<h2 mat-dialog-title>Neue Buchung hinzufügen</h2>
<mat-dialog-content>
    <mat-stepper orientation="horizontal" [linear]="false" #stepper (selectedIndexChange)="onSelectionChange()">
        <mat-step [stepControl]="contactFormGroup">
          <form [formGroup]="contactFormGroup">
            <ng-template matStepLabel>Kontaktdaten</ng-template>
            <div class="grid grid-cols-2 mt-8 mb-8">
                <mat-form-field class="m-5">
                    <mat-label>Vorname</mat-label>
                    <input type="text" matInput placeholder="Max" formControlName="firstName" required>
                </mat-form-field>
                <mat-form-field class="m-5">
                    <mat-label>Nachname</mat-label>
                    <input type="text" matInput placeholder="Mustermann" formControlName="lastName" required>
                </mat-form-field>
                <mat-form-field class="m-5">
                    <mat-label>Telefonnummer</mat-label>
                    <input type="tel" matInput placeholder="012345678" formControlName="phone">
                </mat-form-field>
                <mat-form-field class="m-5">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput placeholder="info@example.com" formControlName="email">
                </mat-form-field>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="stayFormGroup">
          <form [formGroup]="stayFormGroup">
            <ng-template matStepLabel>Übernachtungsdaten</ng-template>
            <div class="grid grid-cols-2">
                <mat-form-field class="col-span-2">
                    <mat-label>Ankunft und Abreise</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="startDate" placeholder="Ankunft">
                        <input matEndDate formControlName="endDate" placeholder="Abreise">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Anzahl Erwachsene</mat-label>
                    <input type="number" matInput formControlName="numberOfAdults" placeholder="0"
                    required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Anzahl Jugendliche</mat-label>
                    <input type="number" matInput formControlName="numberOfYouths" placeholder="0"
                    required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Belegte Zimmer</mat-label>
                    <mat-select formControlName="occupiedRooms" multiple>
                        <mat-option *ngFor="let room of allRooms" [value]="room">
                            {{room.roomNumber}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        </div>
        </form>
    </mat-step>
</mat-stepper>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false">Schließen</button>
    <button mat-raised-button color="primary" (click)="onContinueClick()">{{isLastStep ? isInEditMode ? 'Speichern' : 'Erstellen' : 'Weiter'}}</button>
    <button *ngIf="isInEditMode && isLastStep" mat-raised-button color="warn" (click)="onDeleteClick()" >Löschen</button>
</mat-dialog-actions>