import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Booking, BookingsService, Room } from '../bookings.service';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details.component.html',
    styleUrls: ['./booking-details.component.css'],
    standalone: false
})
// TODO: atleastone validator for contacts
// TODO: room capacity validator
export class BookingDetailsComponent implements OnInit {

  readonly maximumNumberOfGuests = 34;

  contactFormGroup = this._formBuilder.group({
    firstName: new FormControl<string>('', {validators: [Validators.required]}),
    lastName: new FormControl<string>('', {validators: [Validators.required]}),
    phone: new FormControl<string>(''),
    email: new FormControl<string>('', {validators: [Validators.email]}),
  });

  stayFormGroup = this._formBuilder.group({
    startDate: new FormControl<Date|unknown>('', {validators: [Validators.required]}),
    endDate: new FormControl<Date | unknown>('', {validators: [Validators.required]}),
    numberOfAdults: new FormControl<number | unknown>('', {validators: [Validators.required, Validators.min(1), Validators.max(this.maximumNumberOfGuests)]}),
    numberOfYouths: new FormControl<number | unknown>('', {validators: [Validators.required]}),
    occupiedRooms: new FormControl<Room[]>([], {validators: [Validators.required]}),
  });

  @ViewChild('stepper') private myStepper: MatStepper = undefined!;
  isLastStep: boolean = false;
  isInEditMode: boolean = false;
  public allRooms: Room[] = [];
  public booking: Booking | undefined;

  constructor(
    public dialogRef: MatDialogRef<BookingDetailsComponent>,
    private _formBuilder: FormBuilder,
    private _bookingsService: BookingsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      booking: Booking | undefined,
      allRooms: Room[] },
      private dateAdapter: DateAdapter<Date>) {
        this.dateAdapter.getFirstDayOfWeek = () => 1;
        this.allRooms = data.allRooms;
        this.booking = data.booking;
      }

  ngOnInit(): void {
    this.isInEditMode = this.data.booking != null
    if(this.isInEditMode)
    {
      this.populateFormWithExistingData();
    }
  }
  
  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  async onContinueClick(): Promise<void> {
    if(!this.isLastStep)
    {
      this.myStepper.next();
    }
    else if(this.isInEditMode)
    {
      await this._bookingsService.updateBooking(this.convertFormData());
      this.dialogRef.close(true);
    }
    else
    {
      await this._bookingsService.createNewBooking(this.convertFormData());
      this.dialogRef.close(true);
    }
  }

  onSelectionChange()
  {
    this.isLastStep = this.myStepper.selectedIndex == this.myStepper.steps.length - 1
  }

  async onDeleteClick()
  {
    await this._bookingsService.deleteBooking(this.booking!.id!)
    this.dialogRef.close(true);
  }

  convertFormData() : Booking
  {
    return {
      contactPerson: this.contactFormGroup.value,
      startDate: this.stayFormGroup.value.startDate,
      endDate: this.stayFormGroup.value.endDate,
      numberOfGuests: {
        Youth: this.stayFormGroup.value.numberOfYouths,
        Adult: this.stayFormGroup.value.numberOfAdults
      },
      occupiedRooms: this.stayFormGroup.value.occupiedRooms,
      id: this.booking?.id,
      bookedAt: this.booking?.bookedAt
    } as Booking
  }

  populateFormWithExistingData()
  {
    this.contactFormGroup.patchValue(this.booking!.contactPerson)
    this.stayFormGroup.patchValue(this.booking!)
    this.stayFormGroup.controls.numberOfAdults.setValue(this.booking!.numberOfGuests.Adult)
    this.stayFormGroup.controls.numberOfYouths.setValue(this.booking!.numberOfGuests.Youth)
    // TODO: Understand why I need to filter allRooms instead of using bookings.rooms to populate value
    const selectedAllRooms = this.allRooms.filter(room => this.booking!.occupiedRooms.map(r => r.roomNumber).includes(room.roomNumber))
    this.stayFormGroup.controls.occupiedRooms.setValue(selectedAllRooms);
  }

}
