import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Booking, BookingsService } from '../bookings.service';

@Component({
    selector: 'app-bookings-overview-calendar',
    templateUrl: './bookings-overview-calendar.component.html',
    styleUrls: ['./bookings-overview-calendar.component.css'],
    standalone: false
})
export class BookingsOverviewCalendarComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private bookingsService: BookingsService)
  {
  }

  public bookings: Booking[] = [];

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({bookings}) => {
      this.bookings = bookings;
    });
  }
}
