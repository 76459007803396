<table mat-table [dataSource]="bookings" matSort matSortActive="startDate" matSortDirection="desc" class="mat-elevation-z8" >

    <ng-container matColumnDef="startDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Ankunft </th>
        <td mat-cell *matCellDef="let element"> {{element.startDate.toLocaleDateString("de-de")}} </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Abreise </th>
        <td mat-cell *matCellDef="let element"> {{element.endDate.toLocaleDateString("de-de")}} </td>
    </ng-container>

    <ng-container matColumnDef="numberOfGuests">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Anzahl Personen </th>
        <td mat-cell *matCellDef="let element"> {{element.sumOfGuests}} </td>
    </ng-container>

    <ng-container matColumnDef="bookedAt">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Gebucht am </th>
        <td mat-cell *matCellDef="let element"> {{element.bookedAt.toLocaleDateString("de-de")}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Rechnung erstellen </th>
        <td mat-cell *matCellDef="let element"> <button mat-button color="primary" (click)="$event.stopPropagation(); createInvoice(element.id)">Rechnung erstellen</button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row.id)" class="hover:cursor-pointer"></tr>
    
</table>
<!--TODO: could add icons here-->
<button mat-fab color="primary" (click)="openAddDialog()">+</button>
