import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingsOverviewComponent } from './bookings/bookings-overview/bookings-overview.component';
import { bookingsResolver } from './bookings/bookings.service';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BookingsOverviewCalendarComponent } from './bookings/bookings-overview-calendar/bookings-overview-calendar.component';

const routes: Routes = [
{
  path: "bookings",
  component: BookingsOverviewComponent,
  resolve: {
    bookings: bookingsResolver
  },
  canActivate: [MsalGuard]
}, {
  path: "calendar",
  component: BookingsOverviewCalendarComponent,
  resolve: {
    bookings: bookingsResolver
  },
  canActivate: [MsalGuard]
},
{ path: 'loggedIn', component: MsalRedirectComponent },
{ path: '**', redirectTo: 'bookings' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
