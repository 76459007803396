import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, map, tap } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  private baseUrl: string = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }

  async getAllBookings() : Promise<Booking[]>
  {
    const httpResponseObservable = this.http.get<Booking[]>(`${this.baseUrl}/api/Bookings/`).pipe(
      map(bookings => bookings.map(booking => {
        booking.endDate = new Date(booking.endDate);
        booking.startDate = new Date(booking.startDate);
        booking.bookedAt = new Date(booking.bookedAt);
        return booking;
      }))
      )
    const httpPromise = firstValueFrom(httpResponseObservable);
    const returnedValue = await httpPromise;
    return returnedValue;
  }

  async createNewBooking(bookingToCreate: Booking){
    const httpResponseObservable = this.http.post(`${this.baseUrl}/api/Bookings/`, bookingToCreate);
    return await firstValueFrom(httpResponseObservable);
  }

  async updateBooking(updatedBooking: Booking){
    const httpResponseObservable = this.http.put(`${this.baseUrl}/api/Bookings/${updatedBooking.id}`, updatedBooking);
    return await firstValueFrom(httpResponseObservable);
  }

  async deleteBooking(bookingId: string)
  {
    const httpResponseObservable = this.http.delete(`${this.baseUrl}/api/Bookings/${bookingId}`);
    return await firstValueFrom(httpResponseObservable);
  }

  async getBooking(bookingId: string) : Promise<Booking>{
    const httpResponseObservable = this.http.get<Booking>(`${this.baseUrl}/api/Bookings/${bookingId}`);
    return await firstValueFrom(httpResponseObservable);
  }

  async getRooms() : Promise<Room[]>{
    const httpResponseObservable = this.http.get<Room[]>(`${this.baseUrl}/api/Rooms/`);
    return await firstValueFrom(httpResponseObservable);
  }

  async createPdfInvoice(bookingId: string){
    const httpResponseObservable = this.http.post(`${this.baseUrl}/api/Bills/createPdf`,  {bookingId}, {responseType: 'blob'});
    var invoice = await firstValueFrom(httpResponseObservable);
    this.downloadFile(invoice, `rechnung_${bookingId}.pdf`);
  }

  private downloadFile(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}

export interface Booking {
  id: string;
  startDate: Date;
  endDate: Date;
  bookedAt: Date;
  numberOfGuests: Record<GuestType, number>;
  contactPerson: ContactPerson;
  occupiedRooms: Array<Room>
}

export interface ContactPerson{
  firstName: string,
  lastName: string,
  phone: string | undefined,
  email: string | undefined
}

export enum GuestType {
  Adult = 'Adult',
  Youth = 'Youth'
}

export interface Room {
  roomNumber: string;
  capacity: number;
}

export const bookingsResolver: ResolveFn<Booking[]> = 
( route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bookingsService: BookingsService = inject(BookingsService)) => {
  return bookingsService.getAllBookings();
}
