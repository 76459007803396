import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Booking, BookingsService } from '../bookings.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';

@Component({
    selector: 'app-bookings-overview',
    templateUrl: './bookings-overview.component.html',
    styleUrls: ['./bookings-overview.component.css'],
    standalone: false
})
export class BookingsOverviewComponent implements OnInit, AfterViewInit {
  bookings: MatTableDataSource<BookingViewModel> = new MatTableDataSource();
  displayedColumns: string[] = ["startDate", "endDate", "numberOfGuests", "bookedAt", "actions"]
  
  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private bookingsService: BookingsService)
  {
  }
  
  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({bookings}) => {
      this.bookings.data = this.convertToViewModel(bookings);
    });
  }

  ngAfterViewInit(): void {
    this.bookings.sort = this.sort;
  }

  @ViewChild(MatSort) sort: MatSort = undefined!;

  async openAddDialog() : Promise<void>
  {
    const allRooms = await this.bookingsService.getRooms();
    const dialogRef = this.dialog.open(BookingDetailsComponent, {
      data: {allRooms},
    });

    dialogRef.afterClosed().subscribe(this.onDetailsClosed.bind(this));
  }

  async onRowClick(bookingId: string)
  {
    const booking = await this.bookingsService.getBooking(bookingId);
    const allRooms = await this.bookingsService.getRooms();

    const dialogRef = this.dialog.open(BookingDetailsComponent, {
      data: {booking, allRooms },
    });
    dialogRef.afterClosed().subscribe(this.onDetailsClosed.bind(this));
  }

  async onDetailsClosed(reloadNeeded: boolean): Promise<void>
  {
    if(reloadNeeded === true)
    {
      const bookings = await this.bookingsService.getAllBookings();
      this.bookings.data = this.convertToViewModel(bookings);
    }
  }

  async createInvoice(bookingId: string): Promise<void>
  {
    console.log("Creating invoice for booking with id: " + bookingId);
    await this.bookingsService.createPdfInvoice(bookingId);
  }

  private convertToViewModel(bookings: Booking[]): BookingViewModel[]
  {
    return bookings.map(booking => {
      return {
        ...booking,
        sumOfGuests: Object.values(booking.numberOfGuests).reduce((a, b) => a + b, 0)
      }
    });
  }
}

interface BookingViewModel extends Booking {
  sumOfGuests: number;
}